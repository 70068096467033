<template>
  <div class="app-context expanded">
    <!-- <app-header :headerOption="headerOption" ref="appheader"></app-header> -->
    <join-progress :progressInfo="progressInfo"></join-progress>
    <div class="context">
      <directive :directiveInfo="directiveInfo"></directive>
      <div class="join">
        <div class="store-btn-wrap row">
          <button type="button" class="active" @click="addInfo()">
            추가정보 입력
          </button>
          <button type="button" class="addInfo" @click="next()">로그인</button>
        </div>
      </div>
    </div>
    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import JoinProgress from "@/components/join/Progress";
import Directive from "@/components/join/Directive";
import AppFooter from "@/components/AppFooter";
import { mapMutations } from "vuex";
export default {
  components: {
    AppHeader,
    JoinProgress,
    Directive,
    AppFooter,
  },
  data() {
    return {
      progressInfo: {
        activeNum: 2,
      },
      headerOption: {
        navOrder: 1,
      },
      directiveInfo: {
        title: "가입완료",
        isTitleNewLine: false,
        content:
          "닥터리퍼 회원으로 가입해 주셔서 감사합니다.\n'추가정보 입력'을 클릭하여 추가정보를 입력하시면\n닥터리퍼의 다양한 서비스를 효율적으로 이용하실 수 있습니다.\n\n추가정보(회원 검색정보 및 소개정보)는\n이후 마이페이지에서도 입력하시거나 수정할 수 있습니다.",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
    };
  },
  created() {},
  mounted() {
    /**
    document.querySelector("html").classList.add("hasFullHeight");
     */
  },
  computed: {},
  methods: {
    ...mapMutations("util", ["SET_PASSED_URL"]),
    addInfo() {
      this.SET_PASSED_URL("search");
      // this.$refs.appheader.callLoginPop("/v/join/search2");
      const sendAppheaderItem = {
          method: "callLoginPop",
          item: "/v/join/search2",
        };
        this.$emit("send-app-header-method", sendAppheaderItem);
    },
    next() {
      // this.$refs.appheader.callLoginPop("/");
      const sendAppheaderItem = {
          method: "callLoginPop",
          item: "/",
        };
        this.$emit("send-app-header-method", sendAppheaderItem);
    },
  },
  beforeDestroy() {
    /**
    if (document.querySelector("html").classList.contains("hasFullHeight")) {
      document.querySelector("html").classList.remove("hasFullHeight");
    }
     */
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/join";
.join {
  .store-btn-wrap {
    width: 390px;
    button {
      font-size: 23px;
      line-height: 34px;
      &.addInfo {
        background: #474747;
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .store-btn-wrap {
    button.active {
      position: relative;
      top: -40px;
      left: 107px;
    }
    button.addInfo {
      position: relative;
      top: 40px;
      left: -107px;
    }
  }
}
@media screen and (max-width: 320px) {
  .store-btn-wrap {
    button.active {
      position: relative;
      top: -40px;
      left: 73px;
    }
    button.addInfo {
      position: relative;
      top: 40px;
      left: -141px;
    }
  }
}
</style>
